<template>
  <div class="rootDiv">
    <div class="retrieve">
      <div class="searchDiv flex flex-jb">
        <div class="flex flex-ac">
          <el-input @keydown.enter.native="submitSearch" @clear="submitSearch" clearable class="w-450" placeholder="请输入"
            v-model="retrieveForm.searchValue">
            <el-button class="fc-0075F6" slot="append" type="primary" icon="el-icon-search" @click="submitSearch">
            </el-button>
          </el-input>
          <div class="ml-40 fs-16 pointer" @click="getDate">
            活动结束时间：{{systemData.cardEndDate?systemData.cardEndDate:'暂无'}}
          </div>
        </div>
        <div class="flex flex-ac">
          <div class="ml-40 fs-16 fc-6b9eff pointer" @click="addList">
            <i class="el-icon-circle-plus-outline mr-10 fs-18"></i>新增
          </div>
          <div class="ml-40 fc-666 fs-16 pointer" @click="removeAllCard">清除卡片信息</div>
        </div>
      </div>
    </div>
    <TableHeightFull class="tableDiv pall-30">
      <!-- 表格 -->
      <EleTable ref="tableRef" slot="table" :tableData="tableData" :columns="columns" height="100%" :border="false"
        @row-click="headerRowClick" v-loading="tabLoading" :row-style="{ cursor: 'pointer' }">
        <template v-slot:image="scope">
          <img :src="scope.row.cardImg" alt="" class="smallImg" v-if="scope.row.cardImg" />
        </template>
      </EleTable>
      <!-- 分页 -->
      <PagingComponent slot="after" :page-info="pageInfo" @getTableData="getTableData" />
    </TableHeightFull>
    <!-- 新增修改弹框 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" top="20vh" width="30%" @close="resetForm">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="卡片名称" prop="cardName" v-if="'cardName' in ruleForm"
          :rules="{required: true, message: '商品名称不能为空', trigger: 'blur'}">
          <el-input type="text" v-model="ruleForm.cardName"></el-input>
        </el-form-item>
        <el-form-item label="卡片图片" prop="cardImg" v-if="'cardImg' in ruleForm"
          :rules="{required: true, message: '图片不能为空', trigger: 'blur'}">
          <upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
            iconName="el-icon-upload" iconSize="26" v-model="ruleForm.cardImg"></upload-file>
        </el-form-item>
				<el-form-item label="最低消费" prop="consume" v-if="'consume' in ruleForm"
				  :rules="{required: true, message: '最低消费不能为空', trigger: 'blur'}">
				  <el-input type="number" v-model="ruleForm.consume"></el-input>
				</el-form-item>
        <el-form-item label="概率" prop="chance" v-if="'chance' in ruleForm"
          :rules="{required: true, message: '概率不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.chance"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort" v-if="'sort' in ruleForm"
          :rules="{required: true, message: '排序不能为空', trigger: 'blur'}">
          <el-input type="number" v-model="ruleForm.sort"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="affirmPopup" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 修改时间 -->
    <el-dialog title="修改" :visible.sync="dialogDateTime" top="20vh" width="30%">
      <div class="pv-30">
        <el-date-picker v-model="cardEndDate" type="datetime" clearable placeholder="选择日期时间" style="width: 100%;"
          value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59">
        </el-date-picker>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogDateTime = false">取 消</el-button>
        <el-button type="primary" @click="affirmDate" :loading="butLoading">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import indexMixin from "@/utils/indexMixin"
export default {
  name: 'gatherCard',
  mixins: [indexMixin],
  components: {
    "EleTable": (resolve) => require(["@/components/public/TSimpleTable"], resolve),
    "TableHeightFull": (resolve) => require(["@/components/public/TableHeightFull"], resolve),
    "PagingComponent": (resolve) => require(["@/components/public/PagingComponent"], resolve),
    "UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
  },
  data () {
    return {
      retrieveForm: {
        searchValue: '',
      },
      tabLoading: false,
      butLoading: false,
      columns: [
        { label: '排序', prop: 'sort', align: 'center', width: '60' },
        { columnType: 'custom', label: '卡片图', prop: 'image', align: 'left', width: '80' },
        { label: '卡片名称', prop: 'cardName', align: 'left', 'show-overflow-tooltip': true },
				{ label: '最低消费', prop: 'consume', align: 'left', 'show-overflow-tooltip': true },
        { label: '获得几率', prop: 'chance', align: 'left', 'show-overflow-tooltip': true },
        { label: '创建时间', prop: 'createDate', align: 'left', width: '300' },
        {
          columnType: 'button', label: '操作', align: 'right', fixed: 'right', width: '150',
          buttons: [
            { type: 'text', text: '编辑', fn: this.editList },
            { type: 'text', text: '删除', fn: this.deleteList },
          ],
        },
      ],
      tableData: [],
      systemData: '',//活动时间
      //弹框
      dialogFormVisible: false,
      dialogTitle: '新增',
      ruleForm: {
        cardName: '',//卡片名称
        cardImg: '',  //图片
				consume:'',
        chance: '',//概率
        sort: '',  //排序
      },
      dialogDateTime: false,
      cardEndDate: '',
      seriesList: [],//商品系列列表
    }
  },
  mounted () {
    console.log(window.Glob)
    this.getTableData()
    this.findSystemConfig()
  },
  methods: {
    //查询
    submitSearch () { },
    // 查询活动时间
    findSystemConfig () {
      this.$http.get('/systemConfig/findSystemConfig').then((result) => {
        let configData = result.data.data
        if (configData.length > 0) {
          this.systemData = configData[0]
        }
      }).catch((err) => { })
    },
    //修改时间
    getDate () {
      if (this.systemData) {
        if (this.systemData.cardEndDate) {
          this.cardEndDate = this.systemData.cardEndDate
        } else {
          this.cardEndDate = ''
        }
        this.dialogDateTime = true
      }
    },
    //确认修改时间
    affirmDate () {
      let systemData = JSON.parse(JSON.stringify(this.systemData))
      systemData.cardEndDate = this.cardEndDate
      this.butLoading = true
      this.$http.post('/systemConfig/editSystemConfig', systemData).then((result) => {
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.butLoading = false
        this.dialogDateTime = false
        this.findSystemConfig()
      }).catch((err) => {
        this.butLoading = false
      })
    },
    //删除所有卡片信息
    removeAllCard () {
      this.$confirm('您确认清除所有卡片信息吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$confirm('您真的确认清除所有卡片信息吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(_ => {
          this.$http.post('/userCard/removeAllCard').then((result) => {
            this.$message({
              message: '清除成功',
              type: 'success'
            })
          }).catch((err) => { })
        }).catch(_ => { })
      }).catch(_ => { })
    },
    //获取表格数据
    getTableData () {
      this.tabLoading = true
      this.$http.get('/card/findCardList', {
        params: {
          currentPage: this.pageInfo.pageNum,
          pageSize: this.pageInfo.pageSize,
          cardName: this.retrieveForm.searchValue,
        }
      }).then(({ data: result }) => {
        this.tabLoading = false
        this.tableData = result.data.list
        this.pageInfo.total = result.data.total
      }).catch(err => {
        this.tabLoading = false
      })
    },
    //跳转详情
    headerRowClick (row) {
      console.log('跳转详情')
      console.log(row)
    },
    //新增列表
    addList () {
      this.dialogTitle = "新增"
      this.dialogFormVisible = true
    },
    //编辑列表
    editList (row) {
      this.dialogTitle = "编辑"
      this.ruleForm = JSON.parse(JSON.stringify(row))
      this.dialogFormVisible = true
    },
    //删除列表
    deleteList (row) {
      this.$confirm('您确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(_ => {
        this.$http.post('/card/removeCardById', { id: row.id }).then(({ data: result }) => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.getTableData()
        })
      }).catch(_ => { })
    },
    //确认弹框
    affirmPopup () {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.butLoading = true
          let apiUrl = ""
          if (this.dialogTitle == '新增') {
            apiUrl = "/card/saveCard"
          } else if (this.dialogTitle == '编辑') {
            apiUrl = "/card/editCard"
          }
          this.$http.post(apiUrl, this.ruleForm).then(({ data: result }) => {
            this.$message({
              message: this.dialogTitle + '成功',
              type: 'success'
            })
            this.butLoading = false
            this.getTableData()
            this.dialogFormVisible = false
          }).catch((err) => {
            this.butLoading = false
          })
        }
      })
    },
    //重置清除校验
    resetForm () {
      Object.assign(
        this.$data.ruleForm,
        this.$options.data().ruleForm
      )
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate()
      })
    },
  },
}
</script>

<style lang="less" scoped>
</style>